import { Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { LandingPageHeader } from "../common/LandingPageHeader";
import { LANDING_PAGE_CONTENT } from "@/constants/landing-page.constants";
import { TestimonialCard } from "../common/TestimonialCard";
import { mq } from "@/config/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";

// const t = [
//   ...LANDING_PAGE_CONTENT.TESTIMONIALS_SECTION_CONTENT.TESTIMONIALS,
//   ...LANDING_PAGE_CONTENT.TESTIMONIALS_SECTION_CONTENT.TESTIMONIALS,
// ];

export const Testimonials = () => {
  return (
    <Stack css={rootStyles}>
      <Stack css={liveRankSectionTitleContainerStyles}>
        <Typography variant="h2">
          {LANDING_PAGE_CONTENT.TESTIMONIALS_SECTION_CONTENT.SURTITLE}
        </Typography>
        <LandingPageHeader>
          {LANDING_PAGE_CONTENT.TESTIMONIALS_SECTION_CONTENT.TITLE}
        </LandingPageHeader>
      </Stack>

      {/* <Stack css={testimonialsContainerStyles}>
        <Stack>
          {t.map((testimonial, index) => (
            <TestimonialCard key={index} testimonial={testimonial} />
          ))}
        </Stack>
      </Stack> */}

      <div css={swiperContainerStyles}>
        <Swiper
          freeMode
          slidesPerView={window.innerWidth > 768 ? 3 : 1}
          loop={true}
          autoplay={{ pauseOnMouseEnter: true, delay: 0 }}
          speed={10000}
          spaceBetween={16}
          modules={[Autoplay, FreeMode]}
        >
          {LANDING_PAGE_CONTENT.TESTIMONIALS_SECTION_CONTENT.TESTIMONIALS.map(
            (testimonial, index) => (
              <SwiperSlide key={index}>
                <TestimonialCard testimonial={testimonial} />
              </SwiperSlide>
            )
          )}
        </Swiper>
      </div>
    </Stack>
  );
};

const rootStyles = css`
  gap: 2rem;
`;

const swiperContainerStyles = css`
  width: calc(100vw - 2rem);
  margin-inline: auto;

  &.swiper-free-mode > .swiper-wrapper {
    transition-timing-function: linear;
  }

  ${mq["md"]} {
    width: calc(100vw - 4rem);
  }
`;

const liveRankSectionTitleContainerStyles = css`
  align-items: center;
  justify-content: center;
  padding-inline: 1rem;
  text-align: center;

  h2 {
    font-weight: 400;
    font-size: clamp(1rem, 2.5vw, 1.5rem);
  }

  h1 {
    max-width: 25rem;
  }
`;

// const animation = keyframes`
//   0% {
//     transform: translateX(0);
//   }
//   100% {
//     transform: translateX(calc(-1 * (50% + 0.5rem)));
//   }
// `;

// const testimonialsContainerStyles = css`
//   overflow: hidden;
//   ${hideScrollbar}
//   padding: 1rem;

//   & > div {
//     animation: ${animation} 120s linear infinite;
//     width: fit-content;
//     gap: 1rem;
//     flex-direction: row;
//     justify-content: center;

//     & > * {
//       ${transition_all}
//       width: 22rem;
//       cursor: pointer;

//       ${mq["md"]} {
//         width: 30rem;
//         :hover {
//           scale: 1.05;
//         }
//       }
//     }

//     ${mq["md"]} {
//       :hover {
//         animation-play-state: paused;
//       }
//     }
//   }
// `;
