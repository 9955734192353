import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { AppModal, Button } from "../../../components";
import { useState, useEffect } from "react";
import { LOCAL_STORAGE } from "../../../config/app.config";
import liveRankIcon from "../../../assets/common/live_rank_icon.svg";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";
import {
  GA4_EVENTS,
  TrackGoogleAnalyticsEvent,
} from "../../../utils/google-analytics";
import { bgColor } from "../../../config/theme";

export const GoToLiveStandingsModal = () => {
  const [showLiveStandingsModal, setShowLiveStandingsModal] = useState(false);
  const { getMiniLeagueStandingsPath } = useGetUrl();

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (
        !localStorage.getItem(LOCAL_STORAGE.DO_NOT_SHOW_LIVE_STANDINGS_MODAL)
      ) {
        setShowLiveStandingsModal(true);
      }
    }, 3000);

    return () => clearTimeout(timeOut);
  }, []);

  const handleCloseShowLiveStandingsModal = () => {
    setShowLiveStandingsModal(false);
    localStorage.setItem(
      LOCAL_STORAGE.DO_NOT_SHOW_LIVE_STANDINGS_MODAL,
      "true"
    );
  };

  const handleOnNavigation = () => {
    TrackGoogleAnalyticsEvent(GA4_EVENTS.GO_TO_LIVE_RANK_CLICKED);
    localStorage.setItem(
      LOCAL_STORAGE.DO_NOT_SHOW_LIVE_STANDINGS_MODAL,
      "true"
    );
  };

  return (
    <AppModal
      open={showLiveStandingsModal}
      onClose={handleCloseShowLiveStandingsModal}
    >
      <Stack css={modalContainerStyles}>
        <Stack css={modalTitleStyles}>
          <img src={liveRankIcon} alt="live-rank" />
          Introducing Live Rank
        </Stack>

        <Typography>
          Your mini-league rank now updates in real time. No more waiting—see
          changes as they happen! 🚀
        </Typography>

        <Button
          onClick={handleOnNavigation}
          to={getMiniLeagueStandingsPath()}
          button="gradient"
          css={modalButtonStyles}
        >
          Take me there
        </Button>
      </Stack>
    </AppModal>
  );
};

const modalButtonStyles = css`
  color: ${bgColor};
  margin-left: auto;
  width: fit-content;
`;

const modalContainerStyles = css`
  padding: 1rem 1rem 0rem 1rem;
  gap: 0.5rem;
`;

const modalTitleStyles = css`
  font-size: clamp(1.2rem, 5vw, 1.5rem);
  font-weight: 600;
  gap: 0.5rem;
  flex-direction: row;
  align-items: center;

  img {
    width: clamp(1.8rem, 5vw, 2.5rem);
  }
`;
