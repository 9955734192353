import { Helmet } from "react-helmet";
import { css } from "@emotion/react";
import { container, mq, primaryGradient } from "../../../config/styles";
import { TeamDetailsForm } from "./TeamDetailsForm";
import { TEAM_DETAIL_PAGE } from "../../../constants/auth-pages.constants";
import { Header, FormContainer } from "../components";
import { useUserContext } from "../../../context/UserContext";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../../config/routes";
import { SESSION_STORAGE } from "../../../config/app.config";
import { useRef } from "react";
import { Button } from "../../../components";
import { NavigateToHomePage } from "../../../utils/hooks/NavigateToHomePage";
import { Stack, Typography } from "@mui/material";
import infoGradient from "../../../assets/icons/info-gradient.svg";

/** Renders the team Details page  */
export const TeamDetailsPage = () => {
  const { user } = useUserContext();
  const location = useLocation();
  const redirectURL = sessionStorage.getItem(SESSION_STORAGE.URL_HISTORY);
  const locationState = useRef(location.state); // location is reset

  if (user === null) {
    return <Navigate to={ROUTES.LANDING_PAGE} />;
  }

  if (
    user &&
    user.teamId &&
    (locationState.current ? !locationState.current.changeTeamButton : true)
  ) {
    return redirectURL ? <Navigate to={redirectURL} /> : <NavigateToHomePage />;
  }

  return (
    <FormContainer>
      <main css={root}>
        <Helmet>
          <title>{TEAM_DETAIL_PAGE.META.TITLE}</title>
        </Helmet>
        <Header title={TEAM_DETAIL_PAGE.TITLE} />

        {/* <AppLogoDivider /> */}

        <TeamDetailsForm />

        <Stack css={secondTimeContainerStyles}>
          <Typography variant="h1">
            <img src={infoGradient} />
            {TEAM_DETAIL_PAGE.SECOND_TIME.TITLE}
          </Typography>
          <Typography variant="h2">
            {TEAM_DETAIL_PAGE.SECOND_TIME.SUBTITLE}
          </Typography>
          <Button button="outline-gradient" to={ROUTES.REGISTER_PAGE}>
            {TEAM_DETAIL_PAGE.SECOND_TIME.BTN}
          </Button>
        </Stack>
      </main>
    </FormContainer>
  );
};

const root = css`
  ${container}
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  ${mq["md"]} {
    margin-top: 0;
  }
`;

const secondTimeContainerStyles = css`
  align-items: start;
  gap: 1rem;

  h1 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    ${primaryGradient};
    background-clip: text;
    color: transparent;
    font-size: clamp(1.2rem, 6vw, 1.8rem);
    font-weight: 700;
  }

  h2 {
    font-size: clamp(1rem, 4vw, 1.2rem);
    font-weight: 500;
  }

  button,
  a {
    font-size: clamp(1rem, 4vw, 1.2rem);
    font-weight: 600;
    height: 3.5rem;
  }
`;
