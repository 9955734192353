import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { LANDING_PAGE_CONTENT } from "@/constants/landing-page.constants";
import { mq } from "@/config/styles";
import { css } from "@emotion/react";
import { TestimonialCard } from "../common/TestimonialCard";

export const TestimonialsRow = ({
  testimonials,
}: {
  testimonials: typeof LANDING_PAGE_CONTENT.TESTIMONIALS_SECTION_CONTENT.TESTIMONIALS;
}) => {
  return (
    <div css={swiperContainerStyles}>
      <Swiper
        freeMode
        loop={true}
        autoplay={{ pauseOnMouseEnter: true }}
        speed={3000}
        spaceBetween={16}
        modules={[Autoplay]}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <TestimonialCard testimonial={testimonial} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

const swiperContainerStyles = css`
  width: calc(100vw - 2rem);
  margin-inline: auto;

  ${mq["md"]} {
    width: calc(100vw - 4rem);
  }
`;
