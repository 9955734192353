import { ROUTES } from "../config/routes";

export const LANDING_PAGE_SECTION_IDS = {
  HIGHLIGHTS_SECTION: "highlights",
  WHY_FPLCHAMP_SECTION: "why-fplchamp",
  TRY_HIGHLIGHTS_SECTION: "try-highlights",
};

export const LANDING_PAGE_CONTENT = {
  META: {
    TITLE: "FPL Champ",
    DESCRIPTION:
      "The Ultimate, yet simple, FPL tool. Packed with insights and fun highlights to keep you ahead in your mini leagues.",
  },
  HERO_SECTION_CONTENT: {
    TITLE: "The Ultimate FPL tool",
    YET_SIMPLE: "yet simple",
    SUBTITLE:
      "Packed with insights and fun highlights to keep you ahead in your mini leagues.",
    BUTTON: "Get Started",
  },
  LIVE_RANK_SECTION_CONTENT: {
    INTRO: {
      SURTITLE: "Introducing",
      TITLE: "Live Rank!",
    },
    TITLE: "Live Rank: No More Waiting!",
    SUBTITLE:
      "Gameweek points refresh in real time, faster than the official FPL site. Stay ahead of the game and track your rank as the action unfolds!",
    BTN: "Check Live Ranks",
  },
  HIGHLIGHTS_SECTION_CONTENT: {
    SURTITLE: "Our Game-Changer",
    TITLE: "Highlights",
    SUBTITLE:
      '"Highlights" capture top performances, funny moments, and epic fails, adding key moments, humor and excitement to your mini-league!',
    HIGHLIGHTS: [
      {
        SURTITLE: "SOME ARE WEIRD",
        TITLE: "Unique Captain",
        SUBTITLE:
          "Spotlights managers who made bold, unconventional captain picks that paid off.",
      },
      {
        SURTITLE: "SOME ARE APPLAUDABLE",
        TITLE: "Best Transfer Outcome",
        SUBTITLE:
          "Managers who made the most impactful transfers, bringing in players that delivered big points and made a difference.",
      },
      {
        SURTITLE: "SOME ARE UNFORTUNATE",
        TITLE: "Unluckiest Manager",
        SUBTITLE:
          "Highlights the manager who suffered the worst luck—missed points, and got less than his expected points",
      },
      {
        SURTITLE: "OTHERS FRANKLY... ARE JUST STINKING",
        TITLE: "Most Decrease Overall",
        SUBTITLE:
          "Shows the manager who took the biggest drop in overall ranking.",
      },
    ],
  },

  HIGHLIGHTS_DEMO_SECTION_CONTENT: {
    SURTITLE: "In 3 simple steps",
    TITLE: { h1: "Try the highlights out for ", span: "yourself" },
    STEPS: [
      {
        TITLE: "Search for your FPL Team",
        DESCRIPTION: "Find your FPL team by searching for it’s name",
      },
      {
        TITLE: "Pick one of your leagues",
        DESCRIPTION: "Select one of your leagues to get cool highlights on",
      },
      {
        TITLE: "Discover cool highlights",
        DESCRIPTION: "It’s gonna be worth it, trust us!",
      },
    ],
    TEAM_DETAILS: {
      TEAM_NAME_TITLE: "Team Details",
      TITLE_TEAM_ID: "Enter your FPL team Id",
      SUBTITLE: "To get your league’s highlights please insert your team Name",
      BTN: "Next",
    },
    LEAGUE: {
      TITLE: "League selection",
      SUBTITLE: "Choose the mini league you want to see the highlights for",
      BTN: "Next",
      BACK_BUTTON: "Pick another team",
    },
    DEMO: {
      TITLE: "Explore FPL highlights",
      SUBTITLE: "20+ exciting mini-league highlights",
      BTN: "Get Started",
    },
  },

  EXTRA_FEATURES_SECTION: {
    SURTITLE: "WHY FPLCHAMP",
    TITLE: "Check what else your FPL is missing",
    CARD1: {
      TITLE: "Quick Captains Rundown",
      SUBTITLE:
        "No more detective work and snooping around each manager’s team, see all captains in one place.",
    },
    CARD2: {
      TITLE: "How Important Is This Fixture?",
      SUBTITLE:
        "Honestly, in pure FPL terms, who even cares about Ipswich vs Southampton?",
    },
    CARD3: {
      TITLE: "Fixture Breakdown: Who’s Got Who ",
      SUBTITLE:
        "Mbeumo went beast mode?! Phew, nobody… wait… two managers? Come on! Just another day in FPL chaos.",
    },
    CARD4: {
      TITLE: "Most Hurtful Players.",
      SUBTITLE_1: "The Ones That Got Away.",
      SUBTITLE: "Salah: The gift that keeps giving... to everyone but me.",
    },
    CARD5: {
      TITLE: "Who owns a certain player",
      SUBTITLE:
        "So Madueke’s killing it, but apparently, I’m not the only genius..",
    },
    CARD6: {
      TITLE: "Check our other cool features",
      BTN: "View more",
    },
  },

  TESTIMONIALS_SECTION_CONTENT: {
    SURTITLE: "FPL Community",
    TITLE: "Hear what Managers are saying about FPLChamp.com",
    TESTIMONIALS: [
      {
        userName: "Long_Arm_7928",
        comment:
          "Bro, you've proven once more that the world is such a beautiful place with beautiful people. That's judging from the feedback on the comments. Thank you for bringing this up to us and allowing us to take part in it.",
      },
      {
        userName: "Classic_NiceGuy2",
        upVotes: "9",
        comment:
          "This is a {{Pretty Fun}} website! I enjoyed the humor hidden all around, {{Very Clean UI}} and it will definitely appeal to the niche of players who play for fun. I loved the UI and would wanna visit more for sure.",
      },
      {
        userName: "Long_Arm_7928",
        comment:
          "This is a {{great website}} (I'm deliberate with the word great). It gives all the relevant information. Amusing and Stinking facts are really the highlight of the website. You have put good work in this app. I wish you all the best going forward.",
      },

      {
        userName: "lone-survivor",
        upVotes: "22",
        comment:
          "{{Nice website}}. I really liked it. Especially the “stinking” part made me laugh 🤣.I really {{liked your user interface}} too. Great job",
      },
      {
        userName: "misterkalazar",
        upVotes: "86",
        comment:
          "As someone who worked as UI/UX designer, I {{loved your website!}} It was {{responsive}}, didn't see any overflows or anything. {{Impressive work!}}",
      },
      {
        userName: "eglantinel",
        comment:
          "Hey {{this site is Amazing}}, it's literally {{what I have always been looking for}}. This is {{just perfect}}! it had been difficult to find sites offering the fun aspects. So thank you so much for creating this site!",
      },
      {
        userName: "hmsoleander",
        upVotes: "219",
        comment:
          "Immediately being able to {{search by team name}} rather than the number is {{so useful}}. Lost count of how many times I've had to check what my number is",
      },
    ],
  },

  FOOTER: {
    COPY_RIGHT: "© Copyrights FPL Champ | All Rights Reserved",
    FOLLOW_US: "Follow FplChamp",
    QUICK_LINKS: [
      {
        title: "HIGHLIGHTS",
        link: `#${LANDING_PAGE_SECTION_IDS.HIGHLIGHTS_SECTION}`,
      },
      {
        title: "WHY FPLCHAMP",
        link: `#${LANDING_PAGE_SECTION_IDS.WHY_FPLCHAMP_SECTION}`,
      },
      {
        title: "DEMO",
        link: `#${LANDING_PAGE_SECTION_IDS.TRY_HIGHLIGHTS_SECTION}`,
      },
      {
        title: "Contact Us",
        link: ROUTES.CONTACT_US_PAGE,
      },
      {
        title: "TERMS & CONDITIONS",
        link: ROUTES.TERMS_OF_SERVES_PAGE,
      },
      {
        title: "PRIVACY POLICY",
        link: ROUTES.PRIVACY_POLICY_PAGE,
      },
    ],
  },
};
