import { useEffect, useState } from "react";
import { TEAM_DETAIL_PAGE } from "../../../constants/auth-pages.constants";
import { css } from "@emotion/react";
import { bgColorDark, secondaryColor } from "../../../config/theme";
import { postTeamIdAnalytics } from "../../../utils/customAnalytics";
import { Accordion, AutoCompleteInput, Button } from "../../../components";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { IFplManager } from "../../../utils/types/types";
import { FPL_CHAMP_ENDPOINTS } from "../../../config/fplChampEndpoints.constants";
import { axiosInstance } from "../../../config/axios.config";
import { TeamDetailsType } from "../../../utils/validation";
import { Form, Formik } from "formik";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Link } from "react-router-dom";
import teamNameGuideImage from "../../../assets/auth-pages/team-name-guide.png";
import { mq } from "../../../config/styles";
import { useQuery } from "react-query";
import { IUser } from "../../../context/UserContext";

interface ISearchByTeam {
  onSubmit: (
    values: TeamDetailsType,
    { setErrors, setSubmitting }: { setErrors: any; setSubmitting: any }
  ) => void;
  onNotFoundClick: () => void;
  user?: IUser | null;
}

export const SearchByTeam = (props: ISearchByTeam) => {
  // const { user } = useUserContext();
  const [searchParam, setSearchParam] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [fplManager, setFplManager] = useState<IFplManager | null>(null);

  const getManagers = async () => {
    if (!searchParam) return;

    if (props.user) {
      postTeamIdAnalytics({
        email: props.user.email || "noEmail@guestUser.test",
        searchTerm: searchParam,
      });
    }

    const { data: managers } = await axiosInstance.get<IFplManager[]>(
      FPL_CHAMP_ENDPOINTS.GET_TEAM_ID_BY_TEAM_NAME(searchParam, pageNumber)
    );

    if (managers.length === 0) return null;
    return managers;
  };

  const { data: managers, refetch } = useQuery({
    queryKey: ["searchByTeamName", searchParam, pageNumber],
    queryFn: getManagers,
    initialData: [],
    enabled: false,
    cacheTime: Infinity,
  });

  const handleAutoCompleteChange = (inputValue: string) => {
    setSearchParam(inputValue);
    setPageNumber(1);
  };

  const handleItemClick = (item: IFplManager) => {
    setFplManager(item);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchParam) {
        refetch({
          queryKey: ["searchByTeamName", searchParam, pageNumber],
        });
      }
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [searchParam]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchParam) {
        refetch({
          queryKey: ["searchByTeamName", searchParam, pageNumber],
        });
      }
    }, 1);
    return () => clearTimeout(timeoutId);
  }, [pageNumber]);

  const handleCancelClick = () => {
    setFplManager(null);
  };

  const handleShowMore = async () => {
    setPageNumber((prev) => prev + 1);
  };

  const handleGoBack = () => {
    setPageNumber(1);
  };

  return (
    <>
      <Formik initialValues={{ team_id: "" }} onSubmit={props.onSubmit}>
        {({ isSubmitting, setFieldValue, values }) => (
          <Form css={formStyles}>
            <AutoCompleteInput<IFplManager>
              name="team_name"
              id="team_name"
              placeholder={TEAM_DETAIL_PAGE.TEAM_MANE_FIELD_PLACEHOLDER}
              onAutoCompleteChange={handleAutoCompleteChange}
              resultsArray={managers ? managers : null}
              onItemClick={(item) => {
                handleItemClick(item);
                setFieldValue("team_id", item.entry);
              }}
              renderItem={(item) => (
                <Box css={autoCompleteRenderItemStyle}>
                  <Typography>
                    {TEAM_DETAIL_PAGE.CONFIRM_TEAM.TEAM_NAME}: {item.entry_name}
                  </Typography>
                  <Typography>
                    {TEAM_DETAIL_PAGE.CONFIRM_TEAM.MANAGER_NAME}:{" "}
                    {item.player_name}
                  </Typography>
                  <Typography>
                    {TEAM_DETAIL_PAGE.CONFIRM_TEAM.TEAM_ID}: {item.entry}
                  </Typography>
                </Box>
              )}
              item={fplManager ? fplManager : undefined}
              renderFound={(item) => (
                <Box css={foundRenderItemStyle}>
                  <IconButton
                    onClick={() => {
                      handleCancelClick();
                      setFieldValue("team_id", "");
                    }}
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                  <Typography>
                    {TEAM_DETAIL_PAGE.CONFIRM_TEAM.TEAM_NAME}: {item.entry_name}
                  </Typography>
                  <Typography>
                    {TEAM_DETAIL_PAGE.CONFIRM_TEAM.MANAGER_NAME}:{" "}
                    {item.player_name}
                  </Typography>
                  <Typography>
                    {TEAM_DETAIL_PAGE.CONFIRM_TEAM.TEAM_ID}: {item.entry}
                  </Typography>
                </Box>
              )}
              renderShowMore={
                <Stack css={showMoreStyle}>
                  <Button button="gradient" onClick={handleShowMore}>
                    {TEAM_DETAIL_PAGE.SHOW_MORE_BTN}
                  </Button>
                </Stack>
              }
              renderGoBack={
                <Stack css={showMoreStyle}>
                  <Button button="gradient" onClick={handleGoBack}>
                    {TEAM_DETAIL_PAGE.GO_BACK_BTN}
                  </Button>
                </Stack>
              }
              renderNotFound={
                <Stack css={notFoundRenderItemStyle}>
                  <Typography>
                    {TEAM_DETAIL_PAGE.TEAM_NOT_FOUND.TITLE}
                  </Typography>
                  <Button button="gradient" onClick={props.onNotFoundClick}>
                    {TEAM_DETAIL_PAGE.TEAM_NOT_FOUND.BTN}
                  </Button>
                </Stack>
              }
            />

            <Button
              button="CTA"
              type="submit"
              disabled={isSubmitting || values.team_id.length === 0}
            >
              {TEAM_DETAIL_PAGE.CTA_BUTTON_TEXT}
            </Button>
          </Form>
        )}
      </Formik>

      <Accordion title={TEAM_DETAIL_PAGE.FIND_TEAM_NAME.TITLE}>
        <div css={steps}>
          <ol>
            <li>
              {TEAM_DETAIL_PAGE.FIND_TEAM_NAME.GO_TO_FPL.TEXT}{" "}
              <Link
                css={linkStyle}
                to={TEAM_DETAIL_PAGE.FIND_TEAM_NAME.GO_TO_FPL.LINK}
              >
                {TEAM_DETAIL_PAGE.FIND_TEAM_NAME.GO_TO_FPL.LINK_TEXT}
              </Link>
            </li>
            {TEAM_DETAIL_PAGE.FIND_TEAM_NAME.STEPS.map((step, index) => (
              <li key={index}>{step}</li>
            ))}
          </ol>
          <img src={teamNameGuideImage} alt="Team Name Guide" />
        </div>
      </Accordion>
    </>
  );
};

const formStyles = css`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const autoCompleteRenderItemStyle = css`
  & > * {
    :first-of-type {
      font-weight: 700;
      font-size: 1.2rem;
    }
    :last-of-type {
      color: ${secondaryColor};
      font-weight: 700;
      font-size: 0.75rem;
    }
  }
`;

const foundRenderItemStyle = css`
  border: 1px solid ${secondaryColor};
  background-color: ${bgColorDark};
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  & > * {
    :first-of-type {
      font-weight: 700;
      font-size: 1.2rem;
    }
    :last-of-type {
      color: ${secondaryColor};
      font-weight: 700;
      font-size: 0.75rem;
    }
  }

  button {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;

    svg {
      color: white;
      width: 1.2rem;
      aspect-ratio: 1/1;
    }
  }
`;

const steps = css`
  h1 {
    font-size: 1.25rem;
  }

  ol {
    margin: 0;
    padding: 0 1rem;
    font-size: 0.875rem;
    li {
      list-style: decimal;
      padding: 0 0 0.1rem 0.5rem;
    }
  }

  img {
    margin-top: 1rem;
    width: 100%;
  }

  ${mq["md"]} {
    h1 {
      font-size: 1.75rem;
    }
    ol {
      font-size: 1.125rem;
    }
  }
`;

const linkStyle = css`
  color: white;
  text-decoration: underline;
`;

const notFoundRenderItemStyle = css`
  cursor: auto;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  button {
    width: fit-content;
  }
`;

const showMoreStyle = css`
  cursor: pointer;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  button {
    width: 100%;
  }
`;
