import React, { ReactNode, useState } from "react";
import { Input } from "./Input";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { css } from "@emotion/react";
import { Skeleton } from "./Skeleton";
import { bgColor, secondaryColor } from "../config/theme";
import { Stack } from "@mui/material";
import { transition_all } from "../config/styles";
import { TEAM_DETAIL_PAGE } from "../constants/auth-pages.constants";

interface IAutoCompleteInput<T> {
  icon?: ReactNode;
  placeholder: string;
  id: string;
  name: string;
  resultsArray: T[] | null;
  onAutoCompleteChange: (inputValue: string) => void;
  onItemClick: (item: T) => void;
  renderItem?: (item: T) => React.ReactNode;
  renderNotFound?: React.ReactNode;
  renderFound?: (item: T) => React.ReactNode;
  item?: T;
  renderShowMore?: React.ReactNode;
  renderGoBack?: React.ReactNode;
}

/**
 * Auto complete component
 * Show results box if input felid is nopt empty
 * Show loading state if results Array is empty
 *
 * Render Item passes item and expects ReactNode to be returned
 * If no render item is provided results array must be a string[] to render correctly
 */

export const AutoCompleteInput = <T,>(props: IAutoCompleteInput<T>) => {
  const { placeholder, id, name } = props;
  const [showResultsContainer, setShowResultsContainer] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      props.onAutoCompleteChange(e.target.value);
      setInputValue(e.target.value);
      setShowResultsContainer(true);
    } else {
      setInputValue("");
      setShowResultsContainer(false);
    }
  };

  const handleInputClick = () => {
    if (inputValue) {
      setShowResultsContainer(true);
    }
  };

  const handleOnItemClick = (item: T) => {
    props.onItemClick(item);
    setShowResultsContainer(false);
  };

  const handleBlur = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.currentTarget !== event.target) return;
    setShowResultsContainer(false);
  };

  return (
    <div css={autoCompleteRoot}>
      <div css={backdropStyles(showResultsContainer)} onClick={handleBlur} />
      {props.item ? (
        props.renderFound ? (
          props.renderFound(props.item)
        ) : (
          <p>{props.item.toString()}</p>
        )
      ) : (
        <div css={autoCompleteSearchBox}>
          <Input
            Icon={
              props.icon ? (
                props.icon
              ) : (
                <SearchRoundedIcon
                  sx={{ color: "#65656B", fontSize: "1.8rem" }}
                />
              )
            }
            placeholder={placeholder}
            id={id}
            name={name}
            onChange={handleInputChange}
            onClick={handleInputClick}
            value={inputValue}
          />
        </div>
      )}
      <Stack
        component={"ul"}
        css={autoCompleteSearchResults(showResultsContainer)}
      >
        {/** Not found */}
        {!props.resultsArray && (
          <h3 css={teamNameNotFoundStyles}>
            {TEAM_DETAIL_PAGE.TEAM_NAME_NOT_FOUND}
          </h3>
        )}

        {props.resultsArray?.length === 0 ? (
          <LoadingSkeleton />
        ) : /* Render select list */
        props.renderItem ? (
          // Render a custom component
          props.resultsArray?.map((item, i) => (
            <li key={i} onClick={() => handleOnItemClick(item)}>
              {props.renderItem!(item)}
            </li>
          ))
        ) : (
          // Render Simple list of strings
          props.resultsArray?.map((item, i) => (
            <li key={i} onClick={() => handleOnItemClick(item)}>
              {item as string}
            </li>
          ))
        )}

        {
          /** Show more */
          props.renderShowMore && !!props.resultsArray?.length && (
            <li>{props.renderShowMore}</li>
          )
        }

        {
          /** Go back */
          props.renderGoBack && !props.resultsArray?.length && (
            <li>{props.renderGoBack}</li>
          )
        }

        {
          /** Not found */
          props.resultsArray?.length !== 0 && <li>{props.renderNotFound}</li>
        }
      </Stack>
    </div>
  );
};

const LoadingSkeleton = () => {
  return Array.from({ length: 3 }, (_, i) => (
    <Stack key={i}>
      <Skeleton height={35} />
      <Skeleton width={150} height={25} />
    </Stack>
  ));
};

const autoCompleteRoot = css`
  position: relative;
  isolation: isolate;
  min-height: 4rem;
  z-index: 1;
`;

const autoCompleteSearchBox = css`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.8rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  & > *:first-of-type {
    width: 100%;
  }

  button {
    ${transition_all}
    background: ${bgColor};
    border: 2px solid ${secondaryColor};
    border-radius: 1rem;
    height: 100%;
    aspect-ratio: 1;

    :hover {
      background: ${secondaryColor};

      svg {
        ${transition_all}
        color: white;
      }
    }
  }
`;

const teamNameNotFoundStyles = css`
  margin: 0;
`;

const autoCompleteSearchResults = (showResultsContainer: boolean) => css`
  ${transition_all}
  overflow: auto;
  position: absolute;
  top: 4.5rem;
  left: 0;
  z-index: 1;
  width: 100%;
  gap: 0.25rem;
  height: auto;
  padding-inline: 0.5rem;
  padding-block: 0.8rem;
  max-height: 20rem;
  background-color: ${bgColor};
  border-radius: 1rem;
  border: 1px solid #01031a;
  box-shadow: 0px 10px 44px -18px #000000;

  ${!showResultsContainer &&
  css`
    max-height: 0;
    padding-block: 0;
    visibility: hidden;
    opacity: 0;
    z-index: -10;
  `}

  & > * {
    ${transition_all}
    gap: 0.5rem;
    padding: 0.7rem;
    border-radius: 0.5rem;
    background-color: #7e83a911;
    :hover {
      background-color: #9ca1c411;
    }
    cursor: pointer;
  }
`;

const backdropStyles = (display: boolean) => css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  z-index: 1;

  ${display &&
  css`
    display: block;
  `}
`;
