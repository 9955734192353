import React from "react";
import { useNavigateState } from "@/utils/hooks/useNavigateState";
import { postAnalytics } from "@/utils/customAnalytics";
import {
  GA4_EVENTS,
  TrackGoogleAnalyticsEvent,
} from "@/utils/google-analytics";
import { ROUTES } from "@/config/routes";
import { ANALYTICS_TYPES } from "@/constants/global.constants";

export const CTAButtonWrapper = <p extends object>(
  WrappedComponent: React.ComponentType<p>
) => {
  const HOC = (props: p) => {
    const navigate = useNavigateState();
    const handleGetStartedClick = () => {
      postAnalytics({ analyticsType: ANALYTICS_TYPES.CLICK_ON_GET_STARTED });
      TrackGoogleAnalyticsEvent(GA4_EVENTS.LANDING_PAGE_GET_STARTED_CLICKED);
      navigate(ROUTES.TEAM_ID_PAGE);
    };

    return <WrappedComponent onClick={handleGetStartedClick} {...props} />;
  };

  return HOC;
};
