import { useEffect, useRef, useState } from "react";
import { useGetFastHighlightsForLandingPage } from "@/utils/hooks/useGetFastHighlightsForLandingPage";
import { ISingleHighlightCard } from "@/utils/types/types";
import { Button, Carousel, CTACard } from "@/components";
// TODO: Move these components to the components folder
import {
  HighlightsCard,
  HighlightsCardSkeleton,
} from "@/pages/highlights-page/components";
import { css } from "@emotion/react";
import { bgColor, secondaryColor } from "@/config/theme";
import { mq, primaryGradient, transition_all } from "@/config/styles";
import { Stack, Typography } from "@mui/material";
import {
  GA4_EVENTS,
  TrackGoogleAnalyticsEvent,
} from "@/utils/google-analytics";
import { SESSION_STORAGE } from "@/config/app.config";
import Swiper from "swiper";
import { LANDING_PAGE_CONTENT } from "@/constants/landing-page.constants";
import { CTAButtonWrapper } from "../common/CTAButton";

/**
 * HighlightsDemoSectionStep3
 *
 * This component renders a carousel with highlights for demo purpose. It renders
 * a random highlight from each category (stinking, amusing, basic). The component
 * also handles the logic for showing a "Explore Highlights" popup after user has
 * scrolled through the carousel.
 */
export const HighlightsDemoSectionStep3 = () => {
  const { highlightsWithCategory, liveGameWeek } =
    useGetFastHighlightsForLandingPage();

  const [showPopup, setShowPopup] = useState(false);
  const slideViewCount = useRef(0);

  const [randomHighLights, setRandomHighlights] = useState<
    ISingleHighlightCard[]
  >([]);

  useEffect(() => {
    if (highlightsWithCategory) {
      setRandomHighlights(() => []);
      const highLightsByCategory: { [key: string]: ISingleHighlightCard[] } = {
        stinking: [],
        amusing: [],
        basic: [],
      };

      for (const highlight of highlightsWithCategory) {
        highLightsByCategory[highlight.category_id].push(highlight);
      }

      Object.keys(highLightsByCategory).forEach((key) => {
        let randomIndex = 0;
        while (true) {
          randomIndex = Math.floor(
            Math.random() * highLightsByCategory[key].length
          );

          const isIncluded = randomHighLights.find(
            (item) =>
              item.highlight_id ===
              highLightsByCategory[key][randomIndex].highlight_id
          );

          if (!isIncluded) {
            break;
          }
        }

        setRandomHighlights((prev) => [
          ...prev,
          highLightsByCategory[key][randomIndex],
        ]);
      });
    }
  }, [highlightsWithCategory]);

  useEffect(() => {
    if (
      sessionStorage.getItem(SESSION_STORAGE.LANDING_PAGE_LEAGUE_ID) &&
      sessionStorage.getItem(SESSION_STORAGE.LANDING_PAGE_LEAGUE_ID) &&
      !JSON.parse(
        sessionStorage.getItem(SESSION_STORAGE.IS_DEMO_GA_EVENT_FIRED) ||
          "false"
      )
    ) {
      TrackGoogleAnalyticsEvent(GA4_EVENTS.USER_TRIED_DEMO);
      sessionStorage.setItem(
        SESSION_STORAGE.IS_DEMO_GA_EVENT_FIRED,
        JSON.stringify(true)
      );
    }
  }, []);

  const handleSlideChange = (swiper: Swiper) => {
    if (swiper.realIndex === 0 && slideViewCount.current > 0) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
    slideViewCount.current += 1;
  };

  return (
    <div css={carouselContainerStyles}>
      <Carousel
        onRealIndexChange={handleSlideChange}
        hideArrows={{ left: false, right: false }}
      >
        {randomHighLights.length !== 0
          ? randomHighLights
              .sort((a, b) => a.category_order - b.category_order)
              .map((highlight) => (
                <HighlightsCard
                  cardStyles={extraCardStyles}
                  key={`${highlight.category_id}${highlight.highlight_id}`}
                  accentColor={highlight.accent_color}
                  chipImageURL={highlight.category_icon}
                  highlightCardData={highlight}
                />
              ))
          : Array.from({ length: 3 }).map((_, index) => (
              <HighlightsCardSkeleton
                extraStyles={skeletonCardStyles}
                key={index}
              />
            ))}
      </Carousel>
      <span css={circlesStyles} />
      <Typography
        style={{ opacity: randomHighLights.length !== 0 ? 1 : 0 }}
        css={gameWeekStyles}
      >
        {`Showing highlights for GW ${liveGameWeek}`}
      </Typography>

      <CTACard show={showPopup} onCloseBtnClick={() => setShowPopup(false)}>
        <Stack css={ctaCardTextStyles}>
          <Typography variant="h1">
            {LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT.DEMO.TITLE}
          </Typography>
          <Typography variant="h2">
            {LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT.DEMO.SUBTITLE}
          </Typography>
        </Stack>

        <CTAButton button="gradient">
          {LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT.DEMO.BTN}
        </CTAButton>
      </CTACard>
    </div>
  );
};

const CTAButton = CTAButtonWrapper(Button);

const carouselContainerStyles = css`
  width: 100%;
  position: relative;
`;

const skeletonCardStyles = css`
  background: ${bgColor};
`;

const extraCardStyles = css`
  ${mq["md"]} {
    height: 17rem;
  }
`;

const circlesStyles = css`
  --spread: clamp(1.3rem, 3.5vw, 10rem);

  border-radius: 9999px;
  display: block;
  width: 25rem;
  aspect-ratio: 1/1;
  position: absolute;

  /* bottom: -50%; */
  transform: translateY(-50%);
  left: -5%;
  width: 110%;
  z-index: -1;

  /* transition: -50% -50%; */
  pointer-events: none;

  background-color: rgba(124, 136, 155, 1);
  box-shadow: 0 0 0 var(--spread) rgba(155, 245, 251, 0.15),
    0 0 0 calc(2 * var(--spread)) rgba(255, 255, 255, 0.1);
  opacity: 0.5;

  ${mq["lg"]} {
    top: 50%;
    transform: translateY(-50%);
    right: -40%;
    left: auto;
    width: 25rem;
  }
`;

const gameWeekStyles = css`
  ${transition_all}
  margin-top: 0.5rem;
  color: ${secondaryColor};
  font-size: 0.875rem;
  font-weight: 600;
`;

const ctaCardTextStyles = css`
  h1 {
    ${primaryGradient}
    background-clip: text;
    color: transparent;
    font-weight: 700;
    font-size: clamp(1.2rem, 4vw, 1.5rem);
    text-align: center;
  }

  h2 {
    font-size: clamp(0.875rem, 2.5vw, 1.2rem);
    font-weight: 500;
    text-align: center;
  }
`;
