import { useEffect, useState } from "react";
import { GridColDef, GridEventListener } from "@mui/x-data-grid";
import {
  AppTable,
  AppTableSkeleton,
  RankIndicator,
} from "../../../../components";
import { columnNumber } from "../../../../utils/muiTableColumns";
import {
  IMiniLeagueStanding,
  useGetMiniLeagueData,
} from "../../../../utils/hooks/useGetMiniLeagueData";
import { Stack } from "@mui/material";
import { useNavigateState } from "../../../../utils/hooks/useNavigateState";
import { useGetUrl } from "../../../../utils/hooks/useGetUrl";
import { tableDividerColor } from "../../../../config/theme";
import { useGetMiniLeagueStandingsTableHistory } from "../../../../utils/hooks/useGetMiniLeagueStandingsTableHistory";
import {
  extraColumns,
  getGameWeekColumn,
  liveGWCaptainPointsColumn,
  teamColumn,
  getStandingsTableHistoryRows,
  IMiniLeagueStandingsTableRow,
  IMiniLeagueStandingsTableColumns,
  miniLeagueStandingsPLayersLeftColumn,
} from "../../../../utils/tableColumns/miniLeagueTableColumns";
import { useSearchParams } from "react-router-dom";
import { PATH_PARAMS, QUERY_PARAMS } from "../../../../config/routes";
import { MiniLeagueStandingsTableHeader } from "./MiniLeagueStandingsTableHeader";

/**
 * Fetch and render the standings table
 */
export const MiniLeagueStandingsTable = () => {
  const [searchParams] = useSearchParams();
  const isLiveRank = searchParams.get(QUERY_PARAMS.IS_LIVE_RANK) === "true";
  const isActiveManagers =
    searchParams.get(QUERY_PARAMS.ACTIVE_MANAGERS) === "true";

  const [isLoading, setIsLoading] = useState(false);
  const [tableRows, setTableRows] = useState<IMiniLeagueStandingsTableRow[]>(
    []
  );

  const [tableColumn, setTableColumn] = useState<
    IMiniLeagueStandingsTableColumns[]
  >([]);

  const [addCaptainsHistory, setAddCaptainsHistory] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 51,
    page: 0,
  });

  const {
    miniLeagueStandings,
    hasNext,
    eventStatus: miniLeagueStatus,
  } = useGetMiniLeagueData({
    isLiveRank: isLiveRank,
    isActiveManagers: isActiveManagers,
    pageNumber: paginationModel.page + 1,
  });

  const getCaptainsHistory = useGetMiniLeagueStandingsTableHistory({
    pageNumber: paginationModel.page + 1,
  });

  const navigate = useNavigateState();
  const { getTeamViewPath } = useGetUrl();

  const setHistoryRows = async () => {
    const miniLeagueCaptainsHistory = await getCaptainsHistory();
    if (!miniLeagueCaptainsHistory) return;

    const rows = getStandingsTableHistoryRows(
      miniLeagueStandings!,
      miniLeagueCaptainsHistory
    );

    setTableRows(rows);

    return getGameWeekColumn(miniLeagueCaptainsHistory[0].gameWeekCaptains);
  };

  const setTableHeaders = (
    captainsArray: GridColDef<IMiniLeagueStanding>[] = []
  ) => {
    const headers: (GridColDef | false)[] = [
      {
        ...columnNumber,
        renderCell: ({ row }: { row: IMiniLeagueStanding }) => (
          <Stack
            height={"100%"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
          >
            <RankIndicator rank={row.rank} lastRank={row.last_rank} />
            {row.rank_sort}
          </Stack>
        ),
      },
      teamColumn,
      ...extraColumns,
      searchParams.get(QUERY_PARAMS.PLAYERS_LEFT) === "true" &&
        miniLeagueStandingsPLayersLeftColumn,
      searchParams.get(PATH_PARAMS.SELECTED_PHASE) === "1" && {
        ...liveGWCaptainPointsColumn,
        headerName: `C.Pts GW${searchParams.get(
          PATH_PARAMS.SELECTED_GAME_WEEK
        )}`,
      },
      ...captainsArray,
    ];

    const filteredHeaders = headers.filter(
      Boolean
    ) as IMiniLeagueStandingsTableColumns[];

    setTableColumn(filteredHeaders);
  };

  useEffect(() => {
    (async () => {
      if (miniLeagueStandings) {
        setIsLoading(true);
        if (addCaptainsHistory) {
          const historyColumns = await setHistoryRows();
          setTableHeaders((historyColumns ? historyColumns : []) as any);
        } else {
          setTableHeaders();
          setTableRows(miniLeagueStandings);
        }

        setIsLoading(false);
      }
    })();
  }, [miniLeagueStandings, addCaptainsHistory]);

  useEffect(() => {
    if (
      miniLeagueStandings &&
      searchParams.get(PATH_PARAMS.SELECTED_PHASE) !== "1"
    ) {
      setTableColumn((prev) => {
        const headers = [...prev];
        const i = tableColumn.findIndex(
          (c) => c.field === liveGWCaptainPointsColumn.field
        );
        if (i !== -1) headers.splice(i, 1);
        return headers;
      });
    }
  }, [miniLeagueStandings, searchParams]);

  const handleRowClick: GridEventListener<"cellClick"> = ({ row, field }) => {
    if (
      (row.activeChip && field === "event_total") ||
      (row.players_left.message && field === "players_left")
    ) {
      console.log("No action for active chip or players left");
      return;
    }
    navigate(getTeamViewPath(row.entry));
  };

  return (
    <div>
      <MiniLeagueStandingsTableHeader
        setTableColumn={setTableColumn}
        addCaptainsHistory={addCaptainsHistory}
        miniLeagueStatus={miniLeagueStatus}
        setAddCaptainsHistory={setAddCaptainsHistory}
      />
      {miniLeagueStandings && !isLoading ? (
        <AppTable
          hideScrollbar
          getRowHeight={() => "auto"}
          columns={tableColumn}
          rows={tableRows}
          dividerColor={tableDividerColor}
          hasNext={hasNext}
          onCellClick={handleRowClick}
          // Pagination
          paginationMode={"server"}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          // Page Count
          rowCount={-1} // unknown page count (MUI)
        />
      ) : (
        <AppTableSkeleton />
      )}
    </div>
  );
};
