import { Box, Snackbar as MuiSnackbar, Stack, Typography } from "@mui/material";
import { ISnackbar, useSnackbarContext } from "../context/SnackbarContext";
import { css } from "@emotion/react";
import { redColor, secondaryColor } from "../config/theme";
import { shadow } from "../config/styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Slide from "@mui/material/Slide";

/**
 * Snackbar Component
 * A component that displays a Material-UI Snackbar based on the state from the Snackbar context.
 */

export const Snackbar = () => {
  const { snackBarState, setSnackBarState, initialSnackBarState } =
    useSnackbarContext();

  const handleClose = () => {
    setSnackBarState(initialSnackBarState);
  };

  return (
    <MuiSnackbar
      onClick={handleClose}
      message={snackBarState.message}
      open={snackBarState.isOpen}
      anchorOrigin={snackBarState.anchorOrigin}
      autoHideDuration={snackBarState.duration}
      onClose={handleClose}
      TransitionComponent={Slide}
    >
      {snackBarState.renderChildren ? (
        <Box>
          <snackBarState.renderChildren />
        </Box>
      ) : (
        <Stack css={snackbarStyle(snackBarState.state)}>
          <TaskAltIcon />
          <Typography>{snackBarState.message}</Typography>
        </Stack>
      )}
    </MuiSnackbar>
  );
};

const snackbarStyle = (state: ISnackbar["state"]) => css`
  ${shadow}
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 0.85rem;
  background-color: ${state === "error" ? redColor : secondaryColor};
  min-width: 20rem;
  max-width: 80%;
  margin-bottom: 2rem;
`;
