import { useEffect, useState } from "react";
import { axiosInstance } from "@/config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "@/config/fplChampEndpoints.constants";
import { SESSION_STORAGE } from "@/config/app.config";
import { IClassicLeagues } from "@/utils/types/types";
import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { AppLogoDivider, AppTooltip, Button, Skeleton } from "@/components";
import { LANDING_PAGE_CONTENT } from "@/constants/landing-page.constants";
import { bgColor, secondaryColor } from "@/config/theme";
import { CupIcon } from "@/components/icons";
import { transition_all } from "@/config/styles";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { MODAL_ERROR_MESSAGES } from "@/constants/global.constants";

interface IHighlightsDemoSectionStep2 {
  onNextStepClick(values: { leagueId: number | undefined }): void;
  onPrevStepClick: () => void;
}
/**
 * HighlightsDemoSectionStep2
 *
 * The second step of the highlights demo section, which allows the user to pick
 * a league to get the highlights for.
 */
export const HighlightsDemoSectionStep2 = (
  props: IHighlightsDemoSectionStep2
) => {
  const [leagues, setLeagues] = useState<IClassicLeagues[]>([]);
  const [selectedLeague, setSelectedLeague] = useState<IClassicLeagues | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!sessionStorage.getItem(SESSION_STORAGE.LANDING_PAGE_TEAM_ID)) return;

    setLoading(true);
    (async () => {
      const { data } = await axiosInstance.get<IClassicLeagues[]>(
        FPL_CHAMP_ENDPOINTS.GET_LEAGUES(
          sessionStorage.getItem(SESSION_STORAGE.LANDING_PAGE_TEAM_ID)!
        )
      );

      setLeagues(data);
      setLoading(false);
    })();
  }, []);

  const handleItemClick = (league: IClassicLeagues) => {
    if (league.rank_count! > 500) {
      return;
    }
    setSelectedLeague(league);
  };

  return (
    <Stack css={secondSectionContainerStyles}>
      <Stack css={teamDetailsContainerStyles}>
        <Typography variant="h1">
          {LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT.LEAGUE.TITLE}
        </Typography>

        <Typography>
          {LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT.LEAGUE.SUBTITLE}
        </Typography>

        <AppLogoDivider />
      </Stack>

      <Stack component={"ul"} css={leaguesListStyles}>
        {loading
          ? Array.from({ length: 3 }, (_, index) => (
              <Stack component={"li"} key={index}>
                <CupIcon />
                <Skeleton width={"50%"} />
              </Stack>
            ))
          : leagues.map((league, index) => (
              <ListItemComp
                key={index}
                league={league}
                onItemClick={() => handleItemClick(league)}
                isSelected={selectedLeague?.id === league.id}
              />
            ))}
      </Stack>

      <Stack css={buttonCContainerStyles}>
        <Button
          button="gradient"
          onClick={() =>
            props.onNextStepClick({ leagueId: selectedLeague?.id })
          }
        >
          {LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT.LEAGUE.BTN}
        </Button>

        <Button button="solid" onClick={props.onPrevStepClick}>
          <ArrowBackRoundedIcon />
          {
            LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT.LEAGUE
              .BACK_BUTTON
          }
        </Button>
      </Stack>
    </Stack>
  );
};

interface IListItemCompProps {
  league: IClassicLeagues;
  isSelected: Boolean;
  onItemClick: () => void;
}

export const ListItemComp = (props: IListItemCompProps) => {
  const ListItem = (
    <Stack
      component={"li"}
      onClick={props.onItemClick}
      style={{ backgroundColor: props.isSelected ? secondaryColor : "" }}
    >
      <CupIcon />
      {props.league.name}
    </Stack>
  );

  return props.league.rank_count! > 500 ? (
    <AppTooltip
      title={MODAL_ERROR_MESSAGES.SELECT_LEAGUE_WITH_MORE_THAN_500_ENTRY}
    >
      {ListItem}
    </AppTooltip>
  ) : (
    ListItem
  );
};

const secondSectionContainerStyles = css`
  padding: 1.5rem;
  background-color: #222232;
  border-radius: 1.5rem;
  gap: 1rem;
  width: 100%;
`;

const teamDetailsContainerStyles = css`
  gap: 1rem;

  h1 {
    text-align: center;
    color: ${secondaryColor};
    font-size: clamp(2rem, 2.5vw, 2.5rem);
  }

  p {
    text-align: center;
  }
`;

const leaguesListStyles = css`
  --list-item-hight: 3.5rem;
  height: calc(var(--list-item-hight) * 3);
  overflow-y: auto;
  background-color: ${bgColor}77;
  border-radius: 1rem;

  & li {
    ${transition_all}
    cursor: pointer;
    min-height: var(--list-item-hight);
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    padding-inline: 1.5rem;

    :hover {
      background-color: ${bgColor};
    }

    :not(:last-of-type) {
      border-bottom: 1px solid #444;
    }
  }
`;

const buttonCContainerStyles = css`
  gap: 1rem;

  & > button:last-of-type {
    /* padding: 0; */
    background-color: ${bgColor};
    color: #c6c5c5;
    border: 0;
  }
`;
