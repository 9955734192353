import { css } from "@emotion/react";
import { Stack, Typography, Button } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { AppTooltip } from "../../../../components";
import { QUERY_PARAMS } from "../../../../config/routes";
import {
  mq,
  transition_all,
  primaryGradient,
  hideScrollbar,
} from "../../../../config/styles";
import {
  tableHeaderBgColor,
  bgColorDark,
  bgColor,
} from "../../../../config/theme";
import { MINI_LEAGUE_PAGE } from "../../../../constants/mini-league.constants";
import {
  TrackGoogleAnalyticsEvent,
  GA4_EVENTS,
} from "../../../../utils/google-analytics";
import {
  IMiniLeagueStandingsTableColumns,
  miniLeagueStandingsPLayersLeftColumn,
} from "../../../../utils/tableColumns/miniLeagueTableColumns";
import { useSearchParams } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";
import { useGlobalInfoContext } from "../../../../context/GlobalInfoContext";
import { useSnackbarContext } from "../../../../context/SnackbarContext";
import {
  ActiveManagersSnackbar,
  PlayersLeftSnackbar,
} from "../../../../components/snack-bar-components";
import { LiveInfoModal } from ".";

interface IMiniLeagueStandingsTableHeaderProps {
  setTableColumn: React.Dispatch<
    React.SetStateAction<IMiniLeagueStandingsTableColumns[]>
  >;
  addCaptainsHistory: boolean;
  setAddCaptainsHistory: React.Dispatch<React.SetStateAction<boolean>>;
  miniLeagueStatus: string | undefined;
}

export const MiniLeagueStandingsTableHeader = (
  props: IMiniLeagueStandingsTableHeaderProps
) => {
  const {
    setTableColumn,
    setAddCaptainsHistory,
    addCaptainsHistory,
    miniLeagueStatus,
  } = props;

  const coveredSectionRef = useRef<HTMLDivElement>(null);
  const { initialSnackBarState, setSnackBarState } = useSnackbarContext();
  const { liveGameWeek, selectedLeague } = useGlobalInfoContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const isLiveRank = searchParams.get(QUERY_PARAMS.IS_LIVE_RANK) === "true";
  const isActiveManagers =
    searchParams.get(QUERY_PARAMS.ACTIVE_MANAGERS) === "true";
  const isPlayersLeft = searchParams.get(QUERY_PARAMS.PLAYERS_LEFT) === "true";

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        e.target.classList.toggle("is-pinned", e.intersectionRatio < 1);
      },
      { threshold: [1] }
    );

    if (coveredSectionRef.current) observer.observe(coveredSectionRef.current);
  }, [coveredSectionRef.current]);

  useEffect(() => {
    if (!searchParams.get(QUERY_PARAMS.IS_LIVE_RANK)) {
      searchParams.set(
        QUERY_PARAMS.IS_LIVE_RANK,
        selectedLeague?.rank_count! < 500 ? "true" : "false"
      );
      setSearchParams(searchParams, { replace: true });
    }

    () => {
      setSnackBarState(initialSnackBarState);
    };
  }, [selectedLeague]);

  const handleShowCaptainHistoryClick = () => {
    TrackGoogleAnalyticsEvent(GA4_EVENTS.PREV_CAPTAINS_CLICKED);
    setAddCaptainsHistory(!addCaptainsHistory);
  };

  const handleLiveRankClick = () => {
    TrackGoogleAnalyticsEvent(GA4_EVENTS.LIVE_RANK_CLICKED);

    if (selectedLeague?.rank_count! > 500) {
      setSnackBarState((prev) => ({
        ...prev,
        isOpen: true,
        state: "error",
        message: "Select a league with less than 500 managers",
      }));
      return;
    }
    const isLive = searchParams.get(QUERY_PARAMS.IS_LIVE_RANK) === "true";
    searchParams.set(QUERY_PARAMS.IS_LIVE_RANK, isLive ? "false" : "true");
    setSearchParams(searchParams);
  };

  const handleLeftPlayersClick = () => {
    TrackGoogleAnalyticsEvent(GA4_EVENTS.STANDINGS_PLAYER_LEFT_CLICK);
    const showLeftPlayers =
      searchParams.get(QUERY_PARAMS.PLAYERS_LEFT) === "true";
    searchParams.set(
      QUERY_PARAMS.PLAYERS_LEFT,
      showLeftPlayers ? "false" : "true"
    );
    setSearchParams(searchParams);
    if (!showLeftPlayers) {
      setTableColumn((prev) => {
        const newColumns = [...prev];
        newColumns.splice(4, 0, miniLeagueStandingsPLayersLeftColumn);
        return newColumns;
      });
    } else {
      setTableColumn((prev) => {
        const newColumns = [...prev];
        const index = newColumns.findIndex(
          (col) =>
            col.headerName === miniLeagueStandingsPLayersLeftColumn.headerName
        );
        if (index >= 0) newColumns.splice(index, 1);
        return newColumns;
      });
    }
    if (!showLeftPlayers) {
      setSnackBarState((prev) => ({
        ...prev,
        isOpen: true,
        renderChildren: PlayersLeftSnackbar,
      }));
    }
  };

  const handleActiveManagersClick = () => {
    TrackGoogleAnalyticsEvent(GA4_EVENTS.STANDINGS_ACTIVE_MANAGERS_CLICK);
    const active = searchParams.get(QUERY_PARAMS.ACTIVE_MANAGERS) === "true";
    searchParams.set(QUERY_PARAMS.ACTIVE_MANAGERS, active ? "false" : "true");
    setSearchParams(searchParams);
    if (!active) {
      setSnackBarState((prev) => ({
        ...prev,
        isOpen: true,
        renderChildren: ActiveManagersSnackbar,
      }));
    }
  };

  return (
    <>
      <div css={coverSectionStyles} ref={coveredSectionRef} />
      <Stack css={tableHeaderContainerStyles}>
        <Stack css={tableTitleAndLiveBtnContainerStyles}>
          <Typography css={tableTitleStyle}>
            {MINI_LEAGUE_PAGE.TABLE.TITLE}
          </Typography>

          {miniLeagueStatus === "Updated" ? (
            <AppTooltip
              subTitle={MINI_LEAGUE_PAGE.TABLE.LIVE_BTN_TOOLTIP_UPDATED}
              color="#2A2B3D"
            >
              <Stack css={[liveBtnStyles, disabledLiveBtnStyles]}>
                {MINI_LEAGUE_PAGE.TABLE.LIVE_BTN_TITLE_UPDATED}
                <InfoOutlinedIcon />
              </Stack>
            </AppTooltip>
          ) : (
            <Stack css={[liveBtnStyles, activeBtnStyles(isLiveRank)]}>
              <button onClick={handleLiveRankClick}>
                <span className="live-icon" />
                {MINI_LEAGUE_PAGE.TABLE.LIVE_BTN_TITLE}
              </button>
              <LiveInfoModal>
                <InfoOutlinedIcon />
              </LiveInfoModal>
            </Stack>
          )}
        </Stack>

        <Stack css={tableButtons}>
          {liveGameWeek && liveGameWeek > 1 && (
            <Button
              css={btnStyles(addCaptainsHistory)}
              onClick={handleShowCaptainHistoryClick}
            >
              <Typography data-size="lg">
                {MINI_LEAGUE_PAGE.TABLE.BTN_TITLE}
              </Typography>
              <Typography data-size="sm">
                {MINI_LEAGUE_PAGE.TABLE.SMALL_BTN_TITLE}
              </Typography>
              <ArrowForwardIcon />
            </Button>
          )}
          <Button
            css={btnStyles(isPlayersLeft)}
            onClick={handleLeftPlayersClick}
          >
            {MINI_LEAGUE_PAGE.TABLE.PLAYERS_LEFT_BTN}
            <SafetyDividerIcon />
          </Button>
          <Button
            css={btnStyles(isActiveManagers)}
            onClick={handleActiveManagersClick}
          >
            {MINI_LEAGUE_PAGE.TABLE.ACTIVE_MANAGERS_BTN}
            <DirectionsRunIcon />
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

const coverSectionStyles = css`
  width: 100%;
  background-color: ${bgColor};
  height: 0;
  position: sticky;
  top: -2.1rem; // TODO: Remove this magic number
  left: 0;
  z-index: 1;

  &.is-pinned {
    height: 5rem !important;
  }
`;

const tableTitleStyle = css`
  font-size: clamp(1.125rem, 5vw, 2rem);
  font-weight: 500;
  white-space: nowrap;
`;

const tableHeaderContainerStyles = css`
  gap: 0.75rem;
  background: ${tableHeaderBgColor};
  border-radius: 1rem 1rem 0 0;
  padding: 1rem;
  position: sticky;
  z-index: 10;
  top: -0.5rem;

  ${mq["md"]} {
    top: -1rem;
  }
`;

const tableTitleAndLiveBtnContainerStyles = css`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

const liveBtnStyles = css`
  border-radius: 0.5rem;
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  background-color: white;

  button:first-of-type {
    cursor: pointer;
    ${transition_all}
    display: flex;
    align-items: center;
    color: #2a2b3d;
    font-weight: 700 !important;
    height: 2rem !important;
    padding-inline: 0.8rem 0.4rem;
    background: none;
    border: none;
    :hover {
      background-color: #9f9f9f1f;
    }

    .live-icon {
      display: block;
      margin-right: 0.3rem;
      height: 0.7rem;
      width: 0.7rem;
      border-radius: 999px;
      background-color: #e2422c;
    }
  }

  button:last-of-type {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2a2b3d;
    font-weight: 700;
    height: 2rem !important;
    padding-inline: 0.4rem;
    background: none;
    border: none;
    :hover {
      background-color: #6b6b6b1f;
    }
  }
`;

const activeBtnStyles = (isActive: boolean) => css`
  cursor: pointer !important;

  ${isActive &&
  css`
    ${primaryGradient}
  `}
`;

const disabledLiveBtnStyles = css`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  gap: 0.5rem;
  background: #73737368 !important;
  color: #acacacca !important;
  font-weight: 700 !important;
  height: 2rem !important;
  padding-inline: 0.8rem 0.4rem;
  font-size: 0.875rem;
`;

const tableButtons = css`
  flex-direction: row;
  gap: 0.5rem;
  overflow-x: auto;
  ${hideScrollbar}
`;

const btnStyles = (isActive: boolean) => css`
  ${transition_all}
  position: relative;
  height: fit-content;
  padding: 0.3rem 1.2rem;
  color: #ffffff;
  background-color: ${bgColorDark};
  border-radius: 999999px;
  white-space: nowrap;
  min-width: fit-content;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  p[data-size="sm"] {
    display: block;
    font-size: 0.875rem;
  }

  p[data-size="lg"] {
    display: none;
  }

  ${mq["sm"]} {
    p[data-size="sm"] {
      display: none;
    }
    p[data-size="lg"] {
      display: block;
    }
  }

  :hover {
    background-color: #24243a;
  }

  :disabled {
    background-color: #73737368;
    color: #acacacca !important;
  }

  ${isActive &&
  css`
    ${primaryGradient}
    isolation: isolate;
    ::before {
      content: "";
      display: block;
      position: absolute;
      background-color: #181829;
      top: 1.5px;
      left: 1.5px;
      right: 1.5px;
      bottom: 1.5px;
      border-radius: 9999px;
      z-index: -1;
    }
  `}
`;
