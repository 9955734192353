import { Button as MuiButton, ButtonProps } from "@mui/material";
import googleIcon from "../assets/icons/google-icon.svg";
import { css, SerializedStyles } from "@emotion/react";
import { mq, primaryGradient, transition_all } from "../config/styles";
import { bgColor, primaryColor, secondaryColor } from "../config/theme";
import { Link } from "react-router-dom";

interface IButton extends Omit<ButtonProps, "color"> {
  button?:
    | "google"
    | "CTA"
    | "gradient"
    | "text"
    | "outline"
    | "solid"
    | "outline-gradient";
  color?: string;
  children: React.ReactNode | String;
  to?: string;
  state?: any;
  extraCss?: SerializedStyles;
}

/**
 * This component provides a customizable button that can be used throughout the application.
 * if the props.to is passed the button renders a react router Link component
 */

export const Button: React.FunctionComponent<IButton> = ({
  button,
  color,
  children,
  extraCss,
  ...props
}) => {
  const component = props.to ? Link : "button";
  switch (button) {
    case "google":
      return (
        <MuiButton
          LinkComponent={component}
          to={props.to}
          variant="contained"
          css={googleButton}
          {...props}
        >
          <img src={googleIcon} alt="google icon" /> {children}
        </MuiButton>
      );

    case "gradient":
      return (
        <MuiButton
          LinkComponent={component}
          to={props.to}
          css={gradientButtonStyles}
          variant="gradient"
          {...props}
        >
          {children}
        </MuiButton>
      );

    case "outline-gradient":
      return (
        <MuiButton
          LinkComponent={component}
          to={props.to}
          css={[outlineGradientButtonStyles, extraCss]}
          {...props}
        >
          {children}
        </MuiButton>
      );

    case "text":
      return (
        <MuiButton
          LinkComponent={component}
          to={props.to}
          css={[textButtonStyle(color), extraCss]}
          {...props}
        >
          {children}
        </MuiButton>
      );

    case "CTA":
      return (
        <MuiButton
          LinkComponent={component}
          to={props.to}
          css={CTAButton}
          variant="gradient"
          {...props}
        >
          {children}
        </MuiButton>
      );

    case "outline":
      return (
        <MuiButton
          LinkComponent={component}
          to={props.to}
          css={outLineButtonStyles}
          {...props}
        >
          {children}
        </MuiButton>
      );

    case "solid":
      return (
        <MuiButton
          LinkComponent={component}
          to={props.to}
          css={solidButtonStyles(color || primaryColor)}
          {...props}
        >
          {children}
        </MuiButton>
      );

    default:
      return (
        <MuiButton LinkComponent={component} to={props.to} {...props}>
          {children}
        </MuiButton>
      );
  }
};

const gradientButtonStyles = css`
  width: 100%;
`;

const outlineGradientButtonStyles = css`
  width: 100%;
  ${primaryGradient}
  isolation: isolate;
  overflow: hidden;
  color: white;

  :after {
    --border-width: 1px;
    content: "";
    top: var(--border-width);
    left: var(--border-width);
    width: calc(100% - 2 * var(--border-width));
    height: calc(100% - 2 * var(--border-width));
    background-color: ${bgColor};
    border-radius: inherit;
    position: absolute;
    z-index: -1;
  }
`;

const googleButton = css`
  background: #fff;
  color: #61677d;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3.5rem;

  img {
    width: 1.5rem;
    aspect-ratio: 1/1;
  }

  &:hover {
    background-color: #f2f2f2;
  }

  ${mq["md"]} {
    height: 3.75rem;
    font-size: 1.125rem;
  }
`;

const CTAButton = css`
  ${transition_all}
  width: 100%;
  height: 3.75rem;
  font-size: 1rem;
  /* box-shadow: 0px 10px 44px -18px #3461fd; */
  flex: 2;

  :disabled {
    opacity: 0.5;
  }
`;

const textButtonStyle = (color = secondaryColor) => css`
  ${transition_all}
  color: ${color};
  padding: 0.5rem 1rem;
  width: 100%;
  height: 2rem;
  font-size: 1rem;
  flex: 1;

  /* :disabled {
    color: #c4c4c4;
    opacity: 0.5;
  } */
`;

const outLineButtonStyles = css`
  ${transition_all}
  height: 2.5625rem;
  background-color: ${bgColor};
  color: #dddedf;
  border: 1px solid #dddedf;
  width: 100%;
  font-size: 600;

  :hover {
    background-color: #47435520;
  }
`;

const solidButtonStyles = (color: string) => css`
  ${transition_all}
  height: 2.5625rem;
  color: #dddedf;
  background-color: ${color};
  border: 1px solid ${color};
  width: 100%;
  font-size: 600;

  :hover {
    background-color: ${color}66;
  }
`;
