import { Box, Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { LANDING_PAGE_CONTENT } from "@/constants/landing-page.constants";
import fixtureIcon from "@/assets/landing-page/fixture-icon.svg";
import quoteIcon from "@/assets/landing-page/quote-icon.svg";
import card2Image from "@/assets/landing-page/card-2-img.svg";
import card2Swoosh from "@/assets/landing-page/card-2-top-swoosh.svg";
import { ExtraFeaturesIconContainer } from "./ExtraFeaturesIconContainer";
import { ExtraFeaturesCardContainer } from "./ExtraFeaturesCardContainer";

/**
 * A component that renders the second card in the Extra Features section of the
 * landing page. This card displays a fixture icon and some text about the
 * different types of fixtures that are available in the mini league.
 */
export const ExtraFeaturesCard2 = () => {
  return (
    <ExtraFeaturesCardContainer>
      <Stack css={cardContentStyles}>
        <Box css={iconStyles}>
          <ExtraFeaturesIconContainer icon={fixtureIcon} />
        </Box>
        <Stack css={titlesStyles}>
          <Typography variant="h1">
            {LANDING_PAGE_CONTENT.EXTRA_FEATURES_SECTION.CARD2.TITLE}
            <img src={quoteIcon} css={quoteIconStyles} />
          </Typography>
          <Typography variant="h2">
            {LANDING_PAGE_CONTENT.EXTRA_FEATURES_SECTION.CARD2.SUBTITLE}
          </Typography>
        </Stack>
        <img src={card2Image} />
        <img src={card2Swoosh} css={swooshStyles} />
      </Stack>
    </ExtraFeaturesCardContainer>
  );
};

const cardContentStyles = css`
  height: 100%;
  padding-block: var(--spacing);
  gap: var(--spacing);
`;

const iconStyles = css`
  max-width: fit-content;
  margin-left: var(--spacing);
`;

const titlesStyles = css`
  flex: 1;
  padding-inline: var(--spacing);
  h1 {
    position: relative;
    font-size: clamp(1.5rem, 3vw, 2rem);
    font-weight: 600;
    margin-bottom: 0.8rem;
  }
  h2 {
    font-size: clamp(1rem, 2.5vw, 1.5rem);
    font-weight: 400;
    color: #98a2b3;
  }
`;

const quoteIconStyles = css`
  position: absolute;
  top: 0;
  right: 0;
  width: clamp(2.5rem, 5vw, 4rem);
`;

const swooshStyles = css`
  position: absolute;
  top: 0;
  right: 0;
  width: 75%;
`;
